.sliderBoxElm { overflow-x:hidden; overflow-y:visible; width:100%; margin:0 auto; background:#fff; border-bottom:1px solid #ccc;
    .sliderBackgroundBox { background-position:top center; background-repeat:no-repeat; background-size:100% auto;
        .sliderOuter { position:relative; padding:2.5vw 0;
            .prevSlide,
            .nextSlide { max-width:80px; max-height:80px; width:4%; position:absolute; top:50%; left:0; transform:translateY(-50%); border-radius:0 5px 5px 0; z-index:20; transition:all 200ms; color:#ccc; cursor:pointer;
                &:hover { background:#f39200;
                    svg { fill:#fff; }
                }
                svg { display:flex; flex-direction:column; justify-content:center; text-align:center; width:90%; height:90%; fill:#ccc; padding:5%; }
            }
            .nextSlide { left:auto; right:0; border-radius:5px 0 0 5px; }

            .slideBox { position:relative; box-sizing:border-box; padding:2.5vw 0; overflow:hidden; margin:0 auto; max-width:1920px;
                .slides { display:flex; flex-direction:row; position:relative; left:0; transition:all 200ms; z-index:10;
                    .slide { width:100%; min-width:100%; display:flex; flex-direction:row; box-sizing:border-box; order:4; padding:0 2.5vw;
                        &.prev { order:0; }
                        &.current { order:1; }
                        &.next { order:2; }

                        &.left {
                            .text { order:1; }
                            .image { order:2; }
                        }

                        .text { order:2; padding:0 2.5vw; flex:1 1 0; white-space:normal; display:flex; flex-direction:column; justify-content:center; }
                    }
                }
            }
        }
    }

    .bubbleBox { display:flex; flex-direction:row; justify-content:center; margin:0 5vw; z-index:20;
        .bubble { width:10px; height:10px; margin:0 5px; cursor:pointer; border-radius:100%; border:2px solid #f39200;
            &.current { background:#f39200; }
        }
    }
}

@media (min-width:1921px) {
    .sliderBoxElm {
        .sliderBackgroundBox {
            .sliderOuter { padding:2vw 0;
                .slideBox { padding:2vw 0; max-width:2300px;
                    .slides {
                        .slide { padding:0 2vw;
                            .text { padding:0 2vw; }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .sliderBoxElm { padding-bottom:2.5%;
        .sliderBackgroundBox { background:none !important;
            .sliderOuter { padding:0;
                .prevSlide,
                .nextSlide { background:rgba(255,255,255, .6); width:10%; top:15vh;
                    &:hover { background:rgba(255,255,255, .6);
                        svg { fill:#ccc; }
                    }
                    svg { fill:#fff; }
                }

                .slideBox { margin:0 auto; padding:0 0 2.5%;
                    .slides {
                        .slide, .slide.left { flex-direction:column; padding:0;
                            .imageBox { order:1; margin:0; }
                            .text { order:2; width:100%; padding:5% 10% 0; box-sizing:border-box; }
                        }
                    }
                }
            }
        }


        .bubbleBox {
            .bubble { width:10px; height:10px; border-width:2px; }
        }
    }
}
