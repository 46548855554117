.imageBox { order:1; flex:1 1 0; box-sizing:border-box; position:relative; display:flex; flex-direction:column; justify-content:center;
    .imageBoxInner { display:flex; flex-direction:column; flex-wrap:wrap; justify-content:center; margin:0 2.5vw; overflow:hidden;
        .imagesBox { position:relative; flex:1; order:1; display:flex; flex-direction:column; justify-content:center; margin:-2.5%; min-height:500px; max-height:500px;
            &.tiles {
                .imgBox { flex:1 1 0; min-height:500px; display:flex; flex-direction:row; flex-wrap:wrap;
                    .img { flex:1 0 45%; margin:2.5%;
                        .lazyLoad { width:100%; height:100%; background-position:center center; background-size:cover; }
                    }
                }
            }

            &.slider { display:flex;
                .imageGroup { position:relative; width:100%; height:100%; overflow:hidden; flex:1;
                    .img { position:absolute; top:0; right:0; bottom:0; left:0; opacity:0; transition:all 200ms; z-index:10; margin:2.5%;
                        &.current { opacity:1; z-index:20; }
                        .lazyLoad { width:100%; height:100%; background-size:cover; background-position:center center; background-repeat:no-repeat; }
                    }

                }
            }
        }
    }

    .imageBubbleBox { position:absolute; left:50%; top:100%; transform:translateX(-50%); z-index:100; white-space:nowrap; margin:2.5% 0;
        .bubble { display:inline-block; margin:0 5px; width:10px; height:10px; border:2px solid #f39200; border-radius:100%; transition:background-color 200ms; cursor:pointer;
            &.current { background:#f39200 !important; }
        }
    }
}

@media (min-width:1921px) {
    .imageBox {
        .imageBoxInner { margin:0 2vw;
            .imagesBox { margin:-2vw; min-height:600px; max-height:600px;
                &.tiles {
                    .imgBox {
                        .img { margin:2%; }
                    }
                }

                &.slider {
                    .imageGroup {
                        .img { margin:2%; }
                    }

                    .imageBubbleBox { margin:2% 0; }
                }
            }
        }
    }
}


@media (max-width:1024px) {
    .imageBox { order:1; overflow:hidden;
        .imageBoxInner { min-height:30vh; max-height:30vh; padding:0; margin:0;
            .imagesBox.tiles,
            .imagesBox.slider { max-width:100%; position:relative; min-height:100%; max-height:30vh; margin:0;
                .imgBox { width:100%; height:30vh; min-height:100%;
                    .img { margin:0;
                        &:not(:first-child) { display:none; }
                    }
                }

                .imageGroup { width:100%; height:30vh; min-height:100%;
                    .img { margin:0; }
                }
            }

            .imageBubbleBox { display:none; }
        }
    }
}
