.galleryBoxElm { overflow-x:hidden; overflow-y:visible; width:100%; margin:0 auto; position:relative; background:#fff; border-bottom:1px solid #ccc; padding-bottom:2.5vw;
    .sliderOuter { position:relative; background-position:top center; background-repeat:no-repeat; background-size:100% auto;
        .prevSlide,
        .nextSlide { max-width:80px; max-height:80px; width:4%; position:absolute; top:50%; left:0; transform:translateY(-50%); border-radius:0 5px 5px 0; z-index:20; transition:all 200ms; color:#ccc; cursor:pointer;
            &:hover { background:#f39200;
                svg { fill:#fff; }
            }
            svg { display:flex; flex-direction:column; justify-content:center; text-align:center; width:90%; height:90%; fill:#ccc; padding:5%; }
        }
        .nextSlide { left:auto; right:0; border-radius:5px 0 0 5px; }

        .slideBox { position:relative; box-sizing:border-box; padding:5vw 0 2.5vw; overflow:hidden; margin:0 auto; max-width:1920px;
            .slides { display:flex; flex-direction:row; position:relative; left:0; transition:all 200ms; z-index:10;
                .slide { width:100%; min-width:100%; display:flex; flex-direction:row; box-sizing:border-box; order:4; padding:0 2.5vw;
                    &.prev { order:0; }
                    &.current { order:1; }
                    &.next { order:2; }

                    &.left {
                        .text { order:1; }
                        .image { order:2; }
                    }

                    .text { order:2; padding:0 2.5vw; flex:1 1 0; white-space:normal; display:flex; flex-direction:column; justify-content:center; }
                    .images { order:1; flex:1; padding:0 2.5vw; display:flex;
                        .imgBox { margin:-2.5vw -2.5vw 0; flex:1 1 0; display:flex; flex-direction:row; flex-wrap:wrap;
                            .img { flex:1 0 45%; margin:2.5vw 2.5% 0;
                                .image { width:100%; height:100%; min-height:400px; max-height:400px; overflow:hidden; cursor:pointer; position:relative;
                                    &:before { content:''; position:absolute; top:0; right:0; bottom:0; left:0; background:rgba(0,0,0,.4); z-index:100; opacity:0; transition:all 200ms; }

                                    &:hover {
                                        &:before { opacity:1; }
                                        .lazyLoad { transform:scale(1.1); }
                                    }
                                    .lazyLoad { width:100%; height:100%; background-position:center center; background-size:cover; transition:all 200ms; }
                                }
                                h5 { line-height:2rem; }
                                .description { font-style:italic; }
                            }
                        }
                    }
                }
            }
        }


        .bubbleBox { display:flex; flex-direction:row; justify-content:center; margin:0 5vw; z-index:20;
            .bubble { width:10px; height:10px; margin:0 5px; cursor:pointer; border-radius:100%; border:2px solid #f39200;
                &.current { background:#f39200; }
            }
        }
    }
}

@media (min-width:1921px) {
    .galleryBoxElm { padding-bottom:2vw;
        .sliderOuter {
            .slideBox { padding:3vw 0 2vw; margin:0 auto; max-width:2300px;
                .slides {
                    .slide { padding:0 2vw;
                        .text { padding:0 2vw; }
                        .images { padding:0 2vw;
                            .imgBox { margin:-2vw -2vw 0;
                                .img { flex:1 0 30vw; margin:2vw 2vw 0; }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:2561px) {
    .galleryBoxElm {
        .sliderOuter {
            .slideBox {
                .slides {
                    .slide {
                        .images {
                            .imgBox {
                                .img { flex:1 0 26vw; }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:3840px) {
    .galleryBoxElm {
        .sliderOuter {
            .slideBox {
                .slides {
                    .slide {
                        .images {
                            .imgBox {
                                .img { flex:1 0 16vw; }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .galleryBoxElm {
        .headlines {
            .logoBox { width:100px; }
        }

        .sliderOuter {

            .slideBox {
                .slides {
                    .slide, .slide.left { flex-direction:column;
                        .images {
                            .imgBox {
                                .img {
                                    .image { padding:0; order:1; max-height:30vh; min-height:30vh; max-width:100%; width:100%; }
                                }
                            }
                        }

                        .text { order:2; width:100%; padding:5% 10% 0; box-sizing:border-box; }
                    }
                }
            }

            .bubbleBox {
                .bubble { border-width:2px; }
            }
        }

    }
}

@media (max-width:600px) {
    .galleryBoxElm { padding-bottom:5%;

        .horizontalShadow { top:0; }

        .sliderOuter {
            .prevSlide,
            .nextSlide { background:rgba(255,255,255, .6); width:10%; top:15vh;
                &:hover { background:rgba(255,255,255, .6);
                    svg { fill:#ccc; }
                }
                svg { fill:#fff; }
            }

            .slideBox { margin:0; padding:0;
                .slides {
                    .slide { padding:0;
                        .images { padding:0;
                            .imgBox {
                                .data { padding:5% 10% 0;
                                    .headline { line-height:1.2rem; margin-bottom:5px; }
                                }
                            }
                        }
                    }
                }
            }

            .bubbleBox { display:none; }
        }
    }
}
