.partnerBoxElm { overflow-x:hidden; overflow-y:visible; width:100%; margin:0 auto; position:relative; background:#fff; padding-bottom:2.5vw;

    .tabSelect { display:none; }

    .tabs { display:flex; flex-direction:row; list-style-type:none; margin:0 auto; padding:0 5vw;
        li { flex:1; text-align:center; margin:0; padding:10px 0 20px; font-size:1rem; line-height:1.3rem; cursor:pointer; transition:all 200ms; text-transform:uppercase; position:relative; word-wrap:break-spaces; display:flex; flex-direction:column; justify-content:flex-end;
            &:after { content:''; position:absolute; bottom:-1px; left:50%; width:0; height:3px; transform:translateX(-50%); transition:width 200ms; z-index:1; background:#f39200; }

            &.current { color:#f39200; font-weight:bold; }

            &.current,
            &:hover { color:#f39200;
                &:after { width:100%; }
            }
            p { font-style:normal; }
        }
    }


    .tabContent {
        .tab { transition:all 200ms; opacity:0; display:none;
            &.current { opacity:1; display:block }

            .sliderOuter { position:relative;
                .prevSlide,
                .nextSlide { max-width:80px; max-height:80px; width:4%; position:absolute; top:50%; left:0; transform:translateY(-50%); border-radius:0 5px 5px 0; z-index:20; transition:all 200ms; color:#ccc; cursor:pointer;
                    &:hover { background:#f39200;
                        svg { fill:#fff; }
                    }
                    svg { display:flex; flex-direction:column; justify-content:center; text-align:center; width:90%; height:90%; fill:#ccc; padding:5%; }
                }
                .nextSlide { left:auto; right:0; border-radius:5px 0 0 5px; }

                .slideBox { position:relative; box-sizing:border-box; padding:2.5vw 0; overflow:hidden; margin:2.5vw auto 0; max-width:1920px;
                    .slides { display:flex; flex-direction:row; position:relative; left:0; transition:all 200ms; z-index:10;
                        .slide { width:100%; min-width:100%; display:flex; flex-direction:row; box-sizing:border-box; order:4; padding:0 2.5vw;
                            &.prev { order:0; }
                            &.current { order:1; }
                            &.next { order:2; }

                            &.left {
                                .image { order:2; }
                            }

                            .images { order:1; flex:1; overflow:hidden; margin:0 2.5vw; display:flex;
                                .imgBox { margin:-2.5vw -2.5vw 0; flex:1 1 0; display:flex; flex-direction:row; flex-wrap:wrap;
                                    .img { flex:1; padding:2.5vw 2.5% 0; box-sizing:border-box;
                                        &:not(:first-child) { border-left:3px solid #ccc; }
                                        .image { display:block; width:100%; height:100%; min-height:200px; max-height:200px;
                                            .lazyLoad { width:100%; height:100%; background-position:center center; background-size:cover; background-repeat:no-repeat; }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


                .bubbleBox { display:flex; flex-direction:row; justify-content:center; margin:0 5vw; z-index:20;
                    .bubble { width:10px; height:10px; margin:0 5px; cursor:pointer; border-radius:100%; border:2px solid #f39200;
                        &.current { background:#f39200; }
                    }
                }
            }
        }
    }

}

@media (min-width:1921px) {
    .partnerBoxElm { padding-bottom:2vw;
        .tabContent {
            .tab {
                .sliderOuter {
                    .slideBox { padding:2vw 0; margin:2vw auto 0; max-width:2300px;
                        .slides {
                            .slide { padding:0 2vw;
                                .images { margin:0 2vw;
                                    .imgBox { margin:-2vw -2vw 0;
                                        .img { padding:2vw 2% 0; }
                                    }
                                }
                            }
                        }
                    }

                    .bubbleBox { margin:0 2.5vw; }
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .partnerBoxElm {
        .headlines {
            .logoBox { width:100px; }
        }

        .tabContent {
            .tab {
                .sliderOuter {

                    .slideBox {
                        .slides {
                            .slide, .slide.left { flex-direction:column;
                                .images {
                                    .imgBox {
                                        .img {
                                            .image { padding:0; order:1; max-height:30vh; min-height:30vh; max-width:100%; width:100%;
                                                .lazyLoad { background-size:100% auto; }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .bubbleBox {
                        .bubble { border-width:2px; }
                    }
                }
            }
        }

    }
}

@media (max-width:800px) {
    .partnerBoxElm { padding-bottom:5%;

        .horizontalShadow { top:0; }

        .tabContent {
            .tab {
                .sliderOuter {
                    .prevSlide,
                    .nextSlide { background:rgba(255,255,255, .6); width:10%; top:15vh;
                        &:hover { background:rgba(255,255,255, .6);
                            svg { fill:#ccc; }
                        }
                        svg { fill:#ccc; }
                    }

                    .slideBox { margin:0; padding:0 0 5%;
                        .slides {
                            .slide { padding:0;
                                .images { padding:0; }
                            }
                        }

                    }
                }
            }
        }
    }
}
