@keyframes spin {
    100% {
        transform:rotate(360deg);
    }
}

@keyframes spinLoader {
    100% {
        transform:translate(-50%,-50%) rotate(360deg);
    }
}

.contact{ background:#fff;
    .contactBackgroundBox { background-position:top center; background-repeat:no-repeat; background-size:100% auto;
        .contactBox { max-width:1920px; width:100%; margin:0 auto; display:flex; flex-direction:row; padding:0 5vw; box-sizing:border-box;
            .contactForm { display:flex; flex-direction:row; width:100%;
                .left { flex:1; }
                .right { flex:1; }

                input[type="text"],
                input[type="mail"],
                textarea { width:80%; margin:0 auto; height:3rem; border-radius:0; border:0; border-bottom:1px solid #233746; padding:10px; font-size:1.17rem; background:none; box-sizing:border-box; text-align:left; margin-bottom:10px; }

                .selectBox { position:relative; display:block; margin:0 auto; border-bottom:1px solid #233746; margin-bottom:10px;
                    &:before { z-index:1; content:''; position:absolute; top:50%; right:2.5vw; width:10px; height:10px; border-style:solid; border-width:0 0 2px 2px; border-color:transparent transparent #ccc #ccc; transform:rotate(-45deg) translateY(-50%); }

                    select { height:3rem; position:relative; cursor:pointer; border:0; width:100%; background:transparent; line-height:3rem; padding:0 10px; box-sizing:border-box; border-radius:25px; text-transform:uppercase; z-index:10; color:#233746; }
                }

                textarea { height:280px; margin:0 auto; }

                div { width:80%; margin:3vw auto; text-align:center; font-size:1.17rem; line-height:20px;
                    input { border-radius:100%; border:1px solid #ccc; margin-right:20px; top:-2px; position:relative; }

                    &.error { color:#f00; display:none; margin:0 auto 30px;
                        &.visible { display:block; }
                    }
                    &.ok { color:green; margin:10px auto 20px; }
                }

                .secCheck { display:none; }

                button { cursor:pointer; font-size:1.25rem; line-height:50px; width:80%; margin:0 auto 20px; border-radius:6px; border:0; padding:0; transition:all 200ms; position:relative; text-transform:uppercase; color:#fff; background:#233746;
                    &:hover { background:#f39200; border-color:#f39200; color:#fff; }
                    &:disabled { border:0 !important; color:#fff !important; background:#ccc !important; }
                    .loader { position:absolute; top:50%; left:50%; display:inline-block; margin:auto; width:1.3rem; height:1.3rem; border-radius:100%; animation:spinLoader 1.5s linear infinite; animation-timing-function:cubic-bezier(0.77, 0, 0.175, 1); transform:translate(-50%,-50%) rotate(0); border:5px solid rgba(255,255,255,0); border-top:5px solid #fff; }
                }
            }
        }
    }
}

@media (min-width:1921px) {
    .contact{
        .contactBackgroundBox {
            .contactBox { max-width:2300px; padding:0 2.5vw; }
        }
    }
}

@media (max-width:1024px) {
    .contact{
        .contactBackgroundBox {
            .contactBox {
                .contactForm {
                    textarea { height:115px; }

                    div { margin:1.5vw auto; }
                }
            }
        }
    }
}


@media (max-width:800px) {
    .contact{
        .contactBackgroundBox {
            .contactBox {
                .contactForm { width:100%; box-sizing:border-box; flex-direction:column;
                    .left { margin:2.5vw 0 0; }
                    .right { margin:0; }

                    input[type="text"],
                    input[type="mail"],
                    textarea { width:100%; height:40px; font-size:1rem; box-sizing:border-box; margin:0 auto; }

                    textarea { height:100px; }

                    div { width:100%; font-size:1rem; line-height:1.4rem;
                        input { top:1px; }
                    }

                    button { font-size:1rem; width:100%; line-height:2.5rem; padding:0; margin:10px 0 5vw; }
                }
            }
        }
    }
}
