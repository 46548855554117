.imprint { transition:all 200ms; max-height:0; overflow:hidden;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3c5064+0,233746+100 */
    background: rgb(60,80,100); /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, rgba(60,80,100,1) 0%, rgba(35,55,70,1) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, rgba(60,80,100,1) 0%,rgba(35,55,70,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(60,80,100,1) 0%,rgba(35,55,70,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c5064', endColorstr='#233746',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    .sections { max-width:1920px; width:100%; display:flex; flex-direction:row; margin:0 auto; padding:0 2.5vw; position:relative; box-sizing:border-box;
        .close { position:absolute; top:20px; right:2.5%; color:#fff; z-index:199; font-size:1.5rem; width:50px; height:50px; line-height:50px; text-align:center; cursor:pointer; transition:all 200ms; border-radius:5px;
            &:before { line-height:50px; }
            &:hover { background:rgba(255,255,255,1); color:#233746; }
        }

        section { width:50%; padding:5vw 2.5vw; line-height:1.3rem; font-style:italic; color:#fff; white-space:pre-line; text-align:left; box-sizing:border-box;
            div { margin-top:20px; }

            hr { width:55%; margin:0; border:0; border-bottom:1px solid #fff; }
            a { color:#fff; }
        }

        .disableCookie { text-decoration:underline; cursor:pointer; }
    }
}

@media (min-width:1921px) {
    .imprint {
        .sections { max-width:2300px; padding:0 2vw;
            section { width:50%; padding:2.5vw 2vw; }
        }
    }
}

@media (max-width:1024px) {
    .imprint {
        .sections { flex-direction:column;
            section { padding:2.5% 2.5% !important; width:100%; }
        }
    }
}
