.header { width:100%; position:fixed; top:0; right:0; left:0; height:100px; z-index:1100; display:flex; flex-direction:column;
    &:before { content:''; position:absolute; right:0; top:100%; left:0; width:100%; height:15px; background:url(../img/shadow.png) top center no-repeat; background-size:auto; }

    .top { height:30px; line-height:30px; background:#f39200; display:flex; flex-direction:row; justify-content:flex-end; color:#fff;
        div { margin:0 20px 0 0;
            strong { font-weight:600; }
            a { text-decoration:none; color:#fff; }
        }
    }

    .bottom { height:70px; line-height:70px; background:#fff; z-index:1100; display:flex; flex-direction:row; justify-content:flex-start; background:url(../img/allgemein/alu_menue.jpg) no-repeat; background-size:cover; position:relative;
        &:before { content:''; position:absolute; right:0; top:0; left:0; width:100%; height:15px; background:url(../img/shadow.png) top center no-repeat; background-size:auto; }

        nav { display:flex; flex-direction:row; justify-content:flex-start; align-content:stretch; align-items:stretch; flex:1; width:100%; margin:0 auto;

            .logoBox { height:70px; text-align:center; display:flex; flex-direction:column; flex:2;
                .logo { display:flex; flex-direction:column; height:70px;
                    svg { height:50px; margin:auto auto; cursor:pointer; }
                }
            }

            .centerBox { display:flex; flex-direction:row; justify-content:center; max-width:1400px; flex:6; width:100%;
                ul { display:flex; flex-direction:row; justify-content:center; list-style-type:none; padding:0; margin:0; width:100%;
                    li { /*flex:1;*/ position:relative;
                        &:not(:last-child) { margin-right:4%; }
                        a {
                            &:hover { color:#f39200; }
                        }

                        ul { display:none; position:absolute; top:100%; left:50%; transform:translateX(-50%); background:rgba(255,255,255,.9); padding:20px; border-radius:10px; box-shadow:0 0 10px rgba(0,0,0,.2); width:auto;
                            &:before { content:''; display:block; position:absolute; top:-10px; left:50%; transform:translateX(-50%); width:0; height:0; border-style:solid; border-width:0 10px 10px 10px; border-color:transparent transparent #fff transparent; }
                            &:hover { display:block; }

                            li { white-space:nowrap;
                                &:not(:last-child) { border-bottom:1px solid #ccc; }

                                a { line-height:3rem; color:#191919; }
                            }
                        }
                    }
                }
                a { line-height:70px; display:block; text-align:center; text-decoration:none; color:#fff; text-transform:uppercase; transition:background-color 200ms;
                    &:hover + ul { display:block; }
                }
            }

            .flex { flex:2;
                &.socials { display:flex; justify-content:flex-end;
                    a { display:flex; text-decoration:none; margin:auto 20px auto 0;
                        svg { width:30px; fill:rgba(255,255,255, .7);
                            &:hover {
                                path { fill:#f39200; }
                            }

                            path { fill:rgba(255,255,255, .7); transition:all 200ms; }
                        }

                        &.instagram {
                            svg { width:25px; }
                        }
                    }
                }
            }
        }

        .menu { display:none; position:absolute; top:0; right:0; width:70px; height:70px; cursor:pointer; transition:all 250ms;
            .menuBox { transition:all 250ms ease; position:relative; width:100%; height:100%; overflow:auto;
                span { border-radius:10px; height:2px; position:absolute; display:block; background:#fff; }

                .line_1 { width:28px; top:25px; left:20px; transform:rotate(0); transition:all 300ms ease; }
                .line_2 { width:28px; top:33px; left:20px; transform:rotate(0); transition:all 300ms ease; }
                .line_3 { width:28px; top:41px; left:20px; transform:rotate(0); transition:all 300ms ease; }

                &.opened { transform:rotate(180deg);
                    .line_1 { top:34px; transform:rotate(45deg); transition:all 300ms ease; }
                    .line_2 { top:34px; transform:rotate(-45deg); transition:all 300ms ease; }
                    .line_3 { top:34px; transform:rotate(-45deg); transition:all 300ms ease; }
                }
            }
        }
    }
}


#overlay { position:fixed; top:0; right:0; bottom:0; background:rgba(0,0,0,.7); transition:opacity 200ms; z-index:1000; cursor:pointer; opacity:0;
    &.overlay-enter,
    &.overlay-enter-done { left:0; opacity:1; }

    &.overlay-exit-active { left:0; opacity:0; }
    &.overlay-exit-done { left:auto; }
}

#mainMenu { position:fixed; top:100px; bottom:0; right:-400px; background:#fff; max-width:400px; width:100%; transition:right 200ms; z-index:1000; overflow:auto;
    &.opened { right:0; }

    .logoBox { text-align:center; text-shadow:none; margin:0 auto;
        svg { width:240px; margin:40px auto; }
    }

    ul { padding:0 30px; list-style-type:none; margin:0;
        a { display:block; line-height:3rem; padding:0 15px; border-bottom:1px solid #ccc; text-decoration:none; color:#191919; transition:all 200ms; text-transform:uppercase;
            &:hover { background:#f9f9f9; }
        }

        ul { padding:0;
            a { padding:0 30px; text-transform:inherit; }
        }
    }
}

@media (max-width:1440px) {
    .header {
        .bottom {
            nav {
                .flex.socials { flex:0; position:absolute; top:0; right:0; bottom:0; padding:0; }

                .centerBox { flex:10; padding-right:120px; box-sizing:border-box;
                    ul {
                        li:not(:last-child) { margin-right:3% }
                    }
                }
            }

            .menu { display:none; }
        }
    }
}

@media (max-width:1280px) {
    .header { height:50px; line-height:50px;
        .top { display:none; }
        .bottom { height:50px;
            nav {
                .flex.socials { right:40px; }

                .logoBox { height:50px; display:block;
                    .logo { height:50px;
                        svg { display:block; height:34px; margin:auto auto; cursor:pointer; }
                    }
                }

                .centerBox { display:none;
                    a { line-height:50px;
                        .icon { line-height:50px; height:50px; margin:0 5px; }
                        &.current { position:relative;
                            &:after { border-width:7px 0 7px 10px; }
                        }
                        .linkText { line-height:50px; height:50px; font-size:1rem; }
                    }
                }
            }

            .menu { display:block; height:50px; width:50px; overflow:hidden;
                .menuBox { overflow:hidden;
                    .line_1 { width:24px; top:16px; left:13px; }
                    .line_2 { width:24px; top:24px; left:13px; }
                    .line_3 { width:24px; top:32px; left:13px; }

                    &.opened { transform:rotate(180deg);
                        .line_1 { top:24px; }
                        .line_2 { top:24px; }
                        .line_3 { top:24px; }
                    }
                }
            }
        }
    }

    #mainMenu { top:40px; max-width:300px;
        .logoBox {
            svg { width:50% !important; margin-bottom:5% !important; }
        }
    }
}

@media (max-width:600px) {
    .header {
        .bottom {
            nav {
                .flex.socials { display:none; }
                .centerBox { display:none; }
            }
        }
    }
    #mainMenu {
        .logoBox {
            .logo { width:80%; margin:10% auto; }
        }

        .linkliste { padding:0 5% 5%;
            a { padding:0 5%; }
        }
    }
}
